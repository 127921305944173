import React, { useState, useEffect, useRef } from "react";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import InvoiceUpdate from "./InvoicesUpdate";
import swal from "sweetalert";
import "./InvoiceDetails.css";
import TinyMceEditor from "../Settings/TinyMceEditor";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Spinner } from "react-bootstrap"; // Import the Bootstrap Spinner component
import { CiMail, CiEdit } from "react-icons/ci";
import { MdOutlinePrint, MdPictureAsPdf } from "react-icons/md";
import QRCode from "qrcode.react";
import numberToWords from "number-to-words";

function InvoiceDetails({ newinvoiceNumber, custId, handleCancel }) {
  const [invoices, setInvoices] = useState([]);
  const [invoiceCount, setInvoiceCount] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLoading3, setIsLoading3] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [itemDetails, setItemDetails] = useState([]);
  const [invoiceNumber, setInvoiceId] = useState(null);
  const [invoiceEdit, setInvoiceEdit] = useState(false);
  const [detailsPage, setDetailsPage] = useState(true);
  const [openMail, setMail] = useState(false);
  const [userEmail, setUserEmail] = useState([]);
  const [custEmail, setCustomerEmail] = useState([]);
  const [customerData, setCustomerData] = useState({
    someName: "",
    firstName: "",
    lastName: "",
    companyName: "",
    customerName: "",
  });
  const jwtToken = localStorage.getItem("jwtToken");
  const convertToIndianWords = (amount) => {
    const ones = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];
    const teens = [
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const tens = [
      "",
      "ten",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const scales = ["", "thousand", "lakh", "crore"];

    // Helper to convert numbers below 1000
    const convertBelowThousand = (num) => {
      let words = "";
      if (num > 99) {
        words += ones[Math.floor(num / 100)] + " hundred ";
        num %= 100;
      }
      if (num > 10 && num < 20) {
        words += teens[num - 11] + " ";
      } else {
        words += tens[Math.floor(num / 10)] + " ";
        words += ones[num % 10] + " ";
      }
      return words.trim();
    };

    // Split number into groups according to the Indian numbering system
    const splitNumberIntoGroups = (num) => {
      const groups = [];
      let isThousandGroup = true;

      while (num > 0) {
        if (isThousandGroup) {
          groups.push(num % 1000); // First group is 3 digits
          num = Math.floor(num / 1000);
          isThousandGroup = false;
        } else {
          groups.push(num % 100); // Subsequent groups are 2 digits
          num = Math.floor(num / 100);
        }
      }

      return groups.reverse();
    };

    // Process the integer part
    let [integerPart, fractionalPart] = amount.toString().split(".");
    integerPart = parseInt(integerPart, 10);
    const groups = splitNumberIntoGroups(integerPart);

    let words = "";
    for (let i = 0; i < groups.length; i++) {
      if (groups[i] > 0) {
        words +=
          convertBelowThousand(groups[i]) +
          " " +
          scales[groups.length - 1 - i] +
          " ";
      }
    }

    words = words.trim() + " rupees";

    // Process the fractional part (paisa)
    if (fractionalPart && parseInt(fractionalPart, 10) > 0) {
      fractionalPart = parseInt(fractionalPart, 10);
      words += ` and ${convertBelowThousand(fractionalPart)} paisa`;
    }

    // Capitalize the first letter and return
    return words.charAt(0).toUpperCase() + words.slice(1);
  };

  const amountInWords = convertToIndianWords(totalAmount);

  const [companyName, setCompanyName] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [bankColumn, setBankColumn] = useState("No");
  const [showQrCode, setShowQrCode] = useState(false);

  const [userDetails, setUserDetails] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const [qrImage, setQrImage] = useState(null);
  const qrRef = useRef();
  const paymentURL = `upi://pay?pa=${bankData.upiId}&am=${invoiceDetails.total}&cu=INR`;
  const [pdfData, setPdfData] = useState(null);
  const [showDetails, setShowDetails] = useState(true);
  const [showOfflinePay, setOfflinePay] = useState(false);
  const [showTaxDeducted, setShowTaxDeducted] = useState(false);
  const [subject, setSubject] = useState("");
  const [offline, setOffline] = useState({});
  const [gstNumber, setGstNumber] = useState("");
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [formData, setFormData] = useState({
    vendorName: "",
    billsNumber: "",
    vendorId: "",
    amountReceived: invoiceDetails.receivables || "", // Initialize with the current value
    note: "",
    paidThrough: "",
    paymentDate: "",
    paymentMode: "",
    reference: "",
    paymentCount: "",
    depositeTo: "Petty Cash",
    bankCharges: "",
    taxDeducted: "",
    // Add other fields as needed
  });
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleUserEmail = (event) => {
    setUserEmail(event.target.value);
  };

  const handleCustomerEmail = (event) => {
    setCustomerEmail(event.target.value);
  };
  const handleCancleClick = () => {
    // Define your condition here
    handleCancel();
  };

  const [Image, setImage] = useState("");

  const generateQrCodeImage = async () => {
    if (qrRef.current) {
      const canvas = qrRef.current.querySelector("canvas");
      if (canvas) {
        const qrDataUrl = canvas.toDataURL("image/png");
        setQrImage(qrDataUrl);
      }
    }
  };

  useEffect(() => {
    generateQrCodeImage();
  }, [paymentURL, qrRef]);
  useEffect(() => {
    if (showQrCode) {
      generateQrCodeImage();
    }
  }, [showQrCode]);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/fetchInvoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const count = data.responseMessage1;
          setInvoiceCount(count);
          setInvoices(data.responseMessage);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);

  const fetchInvData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const formattedId = newinvoiceNumber.replace(/\//g, "!");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchInvoiceNumber/${formattedId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const itemDetails = data.responseMessage;
          setItemDetails(itemDetails);
          const invoiceDetails = data.responseMessage[0];
          setInvoiceDetails(invoiceDetails);
          setTotalAmount(data.responseMessage[0].total);
          const paymentInv = data.responseMessage[0].invoiceNumber;
          setOffline(paymentInv);
          // getCustomerData();
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  useEffect(() => {
    fetchInvData();
  }, [newinvoiceNumber]);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const userId = localStorage.getItem("userId");

    fetch(BASE_URL + `/fetchUserProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userDetails = data.responseMessage;
          setImage(
            `https://documents.zarp.software/zarp_images/${userDetails.logo}`
          );
          setUserDetails(userDetails);
          const companyName = data.responseMessage.companyName;
          setCompanyName(companyName);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, []);

  const encryptedUniqueId = localStorage.getItem("uniqueId1");

  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const postData = {
    uniqueId: decryptedUniqueId,
  };

  useEffect(() => {
    const apiUrl = BASE_URL + "/fetchBankForPdf";
    const makePostRequest = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify(postData),
        });

        if (response.ok) {
          const responseData = await response.json();
          const filteredData = responseData.responseMessage;
          setBankData(filteredData);
          setShowQrCode(true);
          setBankColumn("Yes");
          setLoading(false);
        } else {
          console.error("POST request failed");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    makePostRequest();
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchCustomerById/${custId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage;
          setCustomerData(Data);
          const custEmail = data.responseMessage.email;
          setCustomerEmail(custEmail);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, [custId]);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage[0];
          setGstNumber(Data);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const handleCancelUpdate = () => {
    setInvoiceEdit(false);
    setDetailsPage(true);
    fetchInvData();
  };

  const getCustomerData = (customerId) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    fetch(BASE_URL + `/fetchCustomerById/${customerId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const custEmail = data.responseMessage.email;
          setCustomerEmail(custEmail);
          setCustomerData(data.responseMessage);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  const handleInvoiceClick = (invoiceNumber) => {
    const formattedId = invoiceNumber.replace(/\//g, "!");
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchInvoiceNumber/${formattedId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const itemDetails = data.responseMessage;
          setItemDetails(itemDetails);
          const invoiceDetails = data.responseMessage[0];
          setInvoiceDetails(invoiceDetails);
          setTotalAmount(data.responseMessage[0].total);
          const paymentInv = data.responseMessage[0].invoiceNumber;
          setOffline(paymentInv);
          // fetchOffline();
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  const handleEditClick = (invoiceNumber) => {
    setInvoiceId(invoiceNumber);
    setInvoiceEdit(true);
    setDetailsPage(false);
  };
  const handleMailClick = () => {
    setInvoiceEdit(false);
    setDetailsPage(false);
    setMail(true);
  };

  const handleCloseMailClick = () => {
    setInvoiceEdit(false);
    setDetailsPage(true);
    setMail(false);
  };

  const itemDetails1 = itemDetails.map((item) => {
    return {
      items: item.itemsDetails || "",
      quantity: item.quantity || "",
      rate: item.rate || "",
      discount: item.discount || "",
      amount: item.amount || "",
      discountType: item.discountType || "",
      size: item.size || "",
      taxRate: item.tax || "",
      hsnCode: item.hsnCode || "",
    };
  });

  const font = {
    color: "red",
  };

  const handleBankSelect = () => {
    setShowTaxDeducted(true);
  };

  const handleTaxDeducted = () => {
    setShowTaxDeducted(false);
  };
  const savePDF = () => {
    // Check if PDF data is available
    if (pdfData) {
      // Create an anchor element
      const link = document.createElement("a");
      link.href = pdfData;

      // Define the filename for the PDF
      const fileName = "{invoiceDetails.invoiceNumber}.pdf";

      // Set the download attribute with the desired filename
      link.setAttribute("download", fileName);

      // Programmatically trigger a click event on the anchor to start the download
      link.click();
    } else {
      console.error("Error: PDF data is not available.");
    }
  };

  const showPDF = async () => {
    const formDataObject = {
      total: invoiceDetails.total,
      invoiceNumber: invoiceDetails.invoiceNumber,
      invoiceDate: invoiceDetails.invoiceDate,
      dueDate: invoiceDetails.dueDate,
      organizationName: companyName,
      custmorName: customerData.customerName,
      companyName: companyName,
      terms: invoiceDetails.paymentTerms,
      items: itemDetails1,
      name: bankData.name,
      branch: bankData.bankName,
      accountNo: bankData.accountNumber,
      bankColumn: bankColumn,
      ifscCode: bankData.ifsc,
      bankCheck: bankData.primaryAccount,
      type: bankData.accountType,
      totalInWords: amountInWords,
      subTotal: invoiceDetails.subtotal,
      adjustment: invoiceDetails.adjustment,
      totalDue: invoiceDetails.receivables,
      notes: invoiceDetails.customerNote,
      state: invoiceDetails.state,
      address: userDetails.address1,
      imageName: userDetails.logo,
      uniqueId: decryptedUniqueId,
      sizeColumn: invoiceDetails.sizeCheck,
      discountColumn: invoiceDetails.discountCheck,
      bankColumn: showQrCode ? "YES" : "NO",
      taxColumn: invoiceDetails.taxCheck,
      igst: invoiceDetails.igst,
      cgst: invoiceDetails.cgst,
      sgst: invoiceDetails.sgst,
      orderNo: invoiceDetails.referenceNumber,
      orgMob1: userDetails.contact,
      organizationStreetName: userDetails.address1,
      organizationAddress: userDetails.address2,
      organizationPinCode: userDetails.zipcode,
      custmorAddress: customerData.billAddress1,
      custmorCity: customerData.billCity,
      custmorMob: customerData.billPhone,
      termsAndCondition: invoiceDetails.termsAndCondition,
      organizationGSTNo: gstNumber.gstin,

      templateFormat: "default",

      // type:invoiceDetails.discountType,
    };

    try {
      const response = await fetch(BASE_URL + "/downloadPdf", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formDataObject),
      });

      if (response.ok) {
        const pdfBlob = await response.blob();
        // Create a URL for the PDF blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Create an anchor element to trigger the download
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = `${invoiceDetails.invoiceNumber}.pdf`;
        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up the DOM

        // Optionally set the PDF data for preview
        setPdfData(pdfUrl);
      } else {
        console.error("Error: Failed to generate PDF");
      }
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      // setPdfLoading(false);
    }
  };

  const initialContent = `
  <div className="main-container" style="margin: 10px; padding: 10px; text-align: center; background-color: #4190f2;">
  <h2 style="margin: 10px; padding: 10px; text-align: center;">${invoiceDetails.invoiceNumber}</h2>
  </div>

<div className="content-container">
  <h4 style="margin: 20px; padding: 20px;"> Dear ${invoiceDetails.customerName},</h4>
  <p style="margin: 20px; padding: 20px;" className="my-custom-paragraph">Thank you for your business. Your invoice can be viewed, printed and downloaded as PDF from the link below. You can also choose to pay it online.</p>
  <div className="card" style="border: 1px solid #e8deb5; border-radius: 3px; padding: 26px; margin: 26px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); width: 50%; margin: 0 auto;  background-color: #fefff1;">
      <h4 style="margin: 5px; padding: 5px; text-align: center;">INVOICE AMOUNT</h4>
      <h4 style=" text-align: center; color:red;"> ₹${invoiceDetails.total}</h4>
      <table style="width: 100%; text-align: center;">
      <tr>
          <td>Invoice No</td>
          <td>${invoiceDetails.invoiceNumber}</td>
      </tr>
      <tr>
          <td>Invoice Date</td>
          <td>${invoiceDetails.invoiceDate}</td>
      </tr>
      <tr>
          <td>Due Date</td>
          <td>${invoiceDetails.dueDate}</td>
      </tr>
  
  </table>
  <div style="text-align: center; padding-top:5px;">
  </div>

  </div>

    <div style=" padding: 10px; width: 80%; margin: 0 auto;">
      <h4>Regards</h3>
      <p>${companyName}</p>
    </div>
</div>`;

  const handleMailSubmit = async () => {
    const postData = {
      to: custEmail,
      from: userEmail,
      subject: subject,
      body: editorContent,
      total: invoiceDetails.total,
      invoiceNumber: invoiceDetails.invoiceNumber,
      invoiceDate: invoiceDetails.invoiceDate,
      dueDate: invoiceDetails.dueDate,
      organizationName: companyName,
      custmorName: customerData.customerName,
      companyName: companyName,
      terms: invoiceDetails.paymentTerms,
      items: itemDetails1,
      name: bankData.name,
      branch: bankData.bankName,
      accountNo: bankData.accountNumber,
      bankColumn: bankColumn,
      ifscCode: bankData.ifsc,
      bankCheck: bankData.primaryAccount,
      type: bankData.accountType,
      totalInWords: amountInWords,
      subTotal: invoiceDetails.subtotal,
      adjustment: invoiceDetails.adjustment,
      totalDue: invoiceDetails.receivables,
      notes: invoiceDetails.customerNote,
      state: customerData.billState,
      address: userDetails.address1,
      imageName: userDetails.logo,
      uniqueId: decryptedUniqueId,
      sizeColumn: invoiceDetails.sizeCheck,
      discountColumn: invoiceDetails.discountCheck,
      taxColumn: invoiceDetails.taxCheck,
      igst: invoiceDetails.igst,
      cgst: invoiceDetails.cgst,
      sgst: invoiceDetails.sgst,
      orderNo: invoiceDetails.referenceNumber,
      orgMob1: userDetails.contact,
      organizationStreetName: userDetails.address1,
      organizationAddress: userDetails.address2,
      organizationPinCode: userDetails.zipcode,
      custmorAddress: customerData.billAddress1,
      custmorCity: customerData.billCity,
      custmorMob: customerData.billPhone,
      termsAndCondition: invoiceDetails.termsAndCondition,
      organizationGSTNo: gstNumber.gstin,
    };
    setIsLoading3(true);
    const response = await fetch(BASE_URL + "/send-invoice-email-with-pdf", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Mail Send Successfully",
          });
          handleCloseMailClick();
          setIsLoading3(false);
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading3(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading3(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchOffline = async (customerId) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const requestBody = {
      uniqueId: decryptedUniqueId,
      customerId: customerId,
      invoiceNumber: offline,
    };
    fetch(BASE_URL + `/fetchOfflinePayments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const paymentDetails = data.responseMessage;
          const paymentCount = data.paymentCount;
          setFormData((prevData) => ({
            ...prevData,
            amountReceived: paymentDetails.receivables,
            paymentCount: paymentCount,
            // Set other fields as needed
          }));
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  const insertPayment = async () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const formDataObject = {
      customerName: invoiceDetails.customerName,
      invoiceNumber: invoiceDetails.invoiceNumber,
      customerId: invoiceDetails.customerId,
      uniqueId: decryptedUniqueId,
      amountReceived: formData.amountReceived,
      note: formData.note,
      paidThrough: formData.paidThrough,
      paymentDate: currentDate,
      paymentMode: formData.paymentMode,
      reference: formData.reference,
      bankCharges: formData.bankCharges,
      depositeTo: formData.depositeTo,
      taxDeducted: formData.taxDeducted,
      amountInExcess: "0",
    };
    try {
      const response = await fetch(BASE_URL + "/offlinePayments", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formDataObject),
      });
      if (response.ok) {
        swal({
          icon: "success",
          title: "Payment Added Successfully",
        });
        setOfflinePay(false);
        setDetailsPage(true);
        setShowDetails(true);
      } else {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  function groupByTax(items) {
    return items.reduce((acc, item) => {
      (acc[item.tax] = acc[item.tax] || []).push(item);
      return acc;
    }, {});
  }

  const groupedItems = groupByTax(itemDetails);

  function groupByTax1(items) {
    return items.reduce((acc, item) => {
      const taxRate = item.tax;
      if (!acc[taxRate]) {
        acc[taxRate] = [];
      }
      acc[taxRate].push(item);
      return acc;
    }, {});
  }

  const groupedItems1 = groupByTax1(itemDetails);
  let i = 1;
  const handlePrint = () => {
    if (showQrCode && !qrImage) {
      console.error("QR code image is not yet loaded.");
      return;
    }
    generateQrCodeImage();
    const printableContent = document.getElementById("printable-content");
    if (printableContent) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
       <!DOCTYPE html>
<html lang="en">
<head>
    <title>${invoiceDetails.invoiceNumber}</title>
    <style>
        .item {
            margin: 10px 0;
        }
        *{
            box-sizing: border-box;
        }
        @media print {
            .page-break {
                display: block;
                page-break-before: always;
            }
        }
        body {
            font-family: Arial, sans-serif;
            margin: 0px;
            padding: 0px;          
            height: 100vh;
        }
        .invoice-container {
            max-width: 900px;
            margin: auto;
            padding: 10px;           
        }
        .table {
            width: 100%;
            height: 100%;
            border-collapse: collapse;
            margin-top: -1px;
        }
        .table th {
            padding: 8px;
            border: 1px solid #ddd;
        }
        .table td {
            padding: 6px;
            border: 1px solid #ddd;
        }
        .table thead th {
            background-color: #f2f2f2;
            font-weight: bold;
        }
        .bg-light {
            background-color: black;
        }
        .fw-600 {
            font-weight: 600;
        }
        .text-center {
            text-align: center;
        }
        .text-end {
            text-align: right;
        }
        .text-uppercase {
            text-transform: uppercase;
        }
        .mb-0 {
            margin-bottom: -1px;
            margin-left: -0.5px;
        }
        .py-1 {
            padding-top: 0.25rem;           
        }
        .btn-group {
            display: inline-flex;
        }
        .btn {
            padding: 10px 20px;
            border: none;
            background-color: #007bff;
            color: #fff;
            text-decoration: none;
            cursor: pointer;
        }
        .btn:hover {
            background-color: #0056b3;
        }
        .fst-italic {
            font-style: italic;
        }
        .first-box {
            display: flex;
            position: relative;
        }
        .shop-info {
            position: relative;
            left: 20px;
            top: -17px;
            width: 80%;
        }
        .cust-info {
            position: relative;
            left: 38px;
            top: -17px;
        }
        .shop-logo {          
            padding-left: 10px;
        }
        .sub-total {
            display: flex;
            justify-content: end;
            justify-content: space-between;
        }
        .sub-total-name {
            display: flex;
        }
        .sub-total-value {
            left: -6px;
        }
        .bill-text {
            font-size: 14px;
            padding: 10px;
            margin: 0px 0px;
        }
        .bank-detail {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 120px;
        }        
        .bank-detail-main p {
            font-size: 10px;
            margin: 0px;
            padding: 2px 5px;
            font-weight: bold;
            position: relative;
            bottom: 10px;
        }
        .bank-detail-main p span {
            font-size: 10px;
            margin: 0px;
            padding: 2px 15px;
            font-weight: 200;
        }
        .bank-scanner img {
            position: relative;
            top: -26px;
           left:90px
        }      
        .bank-scanner p {
            position: relative;
            top: -11px;
            left: 90px;
        }
        #total-table {
            border-collapse: collapse;
            margin-top: 18px;
            border: 1px solid #ddd;
            width: 100%;
        }
        .table-container {
            display: table;
            width: 100%;
            position: relative;
        }
        .float-container {
            width: 100%;
        }
        .float-item {
            float: left;
            width: 30%;
             margin-right: 10px; 
            padding: 10px;
            box-sizing: border-box;
        }
        .float-item1 {
            float: left;
            width: 70%;
            margin-right: 15%;
            padding: 2px;
            box-sizing: border-box;
        }
        .float-item2 {
            float: left;
            width: 30%;
            margin-right: 20%;
            padding: 2px;
            box-sizing: border-box;
        }
        .float-item3 {
            float: left;
            width: 70%;
            margin-right: 20%;
            padding: 2px;
            box-sizing: border-box;
        }
        .float-item4 {
            float: left;
            width: 30%;
            margin-right: 20%;
            padding: 2px;
            box-sizing: border-box;
        }
        .float-item5 {
            float: left;
            width: 70%;
            margin-right: 20%;
            padding: 2px;
            box-sizing: border-box;
        }
        .float-item6 {
            float: left;
            width: 30%;
            margin-right: 20%;
            padding: 2px;
            box-sizing: border-box;
        }
        .float-item7 {
            float: left;
            width: 60%;
            margin-right: 60%;
            padding: 0px;
            box-sizing: border-box;
        }
        .float-item8 {
            float: right;
            width: 40%;
            height: 150px;
            margin-right: 20%;
            padding: 0px;
            box-sizing: border-box;
        }
        #toatal-table tr td {
            border: 1px solid #ddd;
            border-collapse: collapse;
        }
        @media print {
            .no-break {
                page-break-inside: avoid;
            }
            .page-break-before {
                page-break-before: always;
            }
            .page-break-after {
                page-break-after: always;
            }
            .backgroun-dark{
             background-color:#f2f2f2 !important;
             -webkit-print-color-adjust:exact;
             print-color-adjust:exact;
            }            
        }
        .font-heading{
            font-size: 16px;
            font-weight: bold;
        }
        tr td{
            border-collapse: collapse;
        }
        .pt-5{
        padding-top:5px;
        }
    </style>
</head>
<body>
    <div class=" main-table container-fluid invoice-container">
        <div class="box1" style="position: relative">
            <div class="table-responsive">
                <table class="table table-bordered border border-secondary mb-0"
                    style="height: 100%; border-collapse: collapse; width: 100%;">
                    <tbody style="border-collapse: collapse;">
                        <tr>
                            <td style="width: 60%   ;">
                                <div class="first-box">
                                    <div class="shop-logo">
                                        <img src=${Image} width="100" height="100" />
                                    </div>
                                    <div class="shop-info">
                                        <h3 style="margin: 14px 0px 5px 0px;">${
                                          companyName || ""
                                        }</h3>
                                         <address>
                                         ${userDetails.address1 || ""} ${
        userDetails.address2 || ""
      }<br>${userDetails.zipcode || ""} ${userDetails.city || ""}
                                         <br>${userDetails.state || ""} <br>
                                         ${
                                           userDetails.contact
                                             ? `MOB:${
                                                 userDetails.contact || ""
                                               }<br>`
                                             : ""
                                         } 
                                          ${
                                            invoiceDetails.gstNumber
                                              ? `GST NO: ${invoiceDetails.gstNumber}`
                                              : ""
                                          }
                                         </address>
                                    </div>
                                </div>
                            </td>
                            <td colspan="2" class="text-center">
                                <h3>
                                    TAX INVOICE
                                </h3>
                        </tr>
                        <tr style="border:none;">
                            <td class="col-7" style="border-left: 1px solid #ddd; border-bottom: none;">
                                <p style="font-size: 12px; position: relative; top: -10px; left: 14px;">Buyer (Bill To) </p>
                                <div class="row gx-2 gy-2">
                                    <div class="col-auto"></div>
                                    <div class="col">
                                        <div class="cust-info">
                                            <h4 style="margin: 14px 0px 5px 0px;" class="font-heading"> ${
                                              invoiceDetails.customerName || ""
                                            }</h4>
                                            <address style="font-size: 15px;">
                                  ${
                                    customerData.billAddress1
                                      ? `${customerData.billAddress1}<br />`
                                      : ""
                                  }
                                  ${
                                    customerData.billCity ||
                                    customerData.billZipcode
                                      ? `
                                  ${
                                    customerData.billCity
                                      ? `${customerData.billCity} `
                                      : ""
                                  }
                                  ${
                                    customerData.billZipcode
                                      ? `${customerData.billZipcode}`
                                      : ""
                                  } <br /> `
                                      : ""
                                  }
                                  ${
                                    invoiceDetails.state ||
                                    customerData.billCountry
                                      ? `
                                  ${
                                    invoiceDetails.state
                                      ? `${invoiceDetails.state} `
                                      : ""
                                  }
                                  ${
                                    customerData.billCountry
                                      ? `${customerData.billCountry}`
                                      : ""
                                  } <br /> `
                                      : ""
                                  }
                                  ${
                                    customerData.mobile
                                      ? `MOB: ${customerData.mobile}<br />`
                                      : ""
                                  }
                                  ${
                                    customerData.gstin
                                      ? `GST No: ${customerData.gstin}<br />`
                                      : ""
                                  }
                                  </address>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td style=" margin: 0px; padding: 0px;">
                                <table style="margin: 0px; padding: 0px; width: 100%; padding: 0px; margin: 0px; border-collapse: collapse; height:100%;">
                                    <tr>
                                        <td style="width: 100px; border: none; width: 50%;">Invoice No:</td>
                                        <td style="border-left:1px solid #ddd; border-right: none; border-top: none;"> ${
                                          invoiceDetails.invoiceNumber || ""
                                        } </td>
                                    </tr>
                                    <tr>
                                        <td style="border-left:none; border-right: none; border-top: 1px solid #ddd;"> Invoice Date:</td>
                                        <td style="border-left:1px solid #ddd; border-right: none; border-top: none;">  ${
                                          invoiceDetails.invoiceDate || ""
                                        } </td>
                                    </tr>
                                    <tr>
                                        <td style="border-left:none; border-right: none; border-top: 1px solid #ddd;">Due:</td>
                                        <td style="border-left:1px solid #ddd; border-right: none; border-top: none;"> ${
                                          invoiceDetails.dueDate || ""
                                        }
                                        </td>
                                    </tr>
                                    ${
                                      invoiceDetails.terms
                                        ? `
                                    <tr>
                                        <td style="border-left:none; border-right: none; border-top: 1px solid #ddd;">
                                            Terms:</td>
                                        <td style="border-left:1px solid #ddd; border-right: none; border-top: none;">
                                        ${invoiceDetails.terms || ""}
                                        </td>
                                    </tr>`
                                        : ""
                                    }
                                    ${
                                      invoiceDetails.orderNumber
                                        ? `
                                    <tr style="border-bottom:none ;">
                                        <td style="border-left:none; border-right: none; border-top: 1px solid #ddd; border-bottom: none;">
                                            P O: </td>
                                        <td style="border-left:1px solid #ddd; border-right: none; border-top: 1px solid #ddd; border-bottom: none;">
                                            ${invoiceDetails.orderNumber || ""}
                                        </td>
                                    </tr>`
                                        : ""
                                    }
                                    <tr style="border-bottom:none ;">
                                    <td style="border-left:none; border-right: none; border-top: 1px solid #ddd; border-bottom: none;">Payment Status: </td>
                                    <td style="border-left:1px solid #ddd; border-right: none; border-top: 1px solid #ddd; border-bottom: none;">${
                                      invoiceDetails.status
                                    }
                                    </td>
                                  </tr>
                              </table>
                              </td>
                              </tr>
                            </tbody>
                            </table>
                    <table class="table table-sm mb-0" style="width: 100%; padding:0px; margin: 0px; border: none; border-collapse: collapse;">
                    <tr class="bg-light backgroun-dark" style="border-collapse: collapse; ">
                        <td class="col-1 text-center  font-heading"><strong>SrNo</strong></td>
                        <td class="col-6 font-heading"><strong>Product Name</strong></td>
                        ${
                          invoiceDetails.hsnCheck === "YES"
                            ? `<td class="col-1 text-center font-heading"><strong>HSN</strong></td>`
                            : ""
                        }
                        ${
                          invoiceDetails.sizeCheck === "YES"
                            ? '<td class="col-1 text-center font-heading"><strong>Size</strong></td>'
                            : ""
                        }
                        <td class="col-1 text-center font-heading"><strong>Qty</strong></td>
                        <td class="col-2 text-end font-heading"><strong>Rate</strong></td>
                        ${
                          invoiceDetails.discountCheck === "YES"
                            ? '<td class="col-2 text-end font-heading"><strong>Discount</strong></td>'
                            : ""
                        }
                        ${
                          invoiceDetails.taxCheck === "YES"
                            ? '<td class="col-2 text-end font-heading"><strong>Tax</strong></td>'
                            : ""
                        }
                        <td class="col-2 text-end font-heading"><strong>Amount</strong></td>
                    </tr>
                    ${itemDetails
                      .map(
                        (item, index) => `
                    <tr class="produc-des " style="page-break-inside: avoid;">
                        <td class="col-1 text-center bill-text">${
                          index + 1
                        }</td>
                        <td class="col-6 bill-text" style="white-space: pre-line;">${
                          item.itemsDetails || ""
                        }<br />${item.description2 || ""}</td>
                        ${
                          invoiceDetails.hsnCheck === "YES"
                            ? `<td class="col-2 text-end bill-text">${
                                item.hsnSac || ""
                              }</td>`
                            : ""
                        }
                                          ${
                                            invoiceDetails.sizeCheck === "YES"
                                              ? `<td class="col-1 text-center bill-text">${
                                                  item.size || ""
                                                }</td>`
                                              : ""
                                          }
                                          <td class="col-1 text-center bill-text">${
                                            item.quantity || ""
                                          }</td>
                                          <td class="col-1 text-center bill-text">${
                                            item.rate || ""
                                          }</td>
                                          ${
                                            invoiceDetails.discountCheck ===
                                            "YES"
                                              ? `<td class="col-1 text-center bill-text">${
                                                  item.discount || ""
                                                }(${
                                                  item.discountType || ""
                                                })</td>`
                                              : ""
                                          }
                                          ${
                                            invoiceDetails.taxCheck === "YES"
                                              ? `<td class="col-1 text-center bill-text">${
                                                  item.tax || ""
                                                }%</td>`
                                              : ""
                                          }
                                          <td class="col-2 text-end bill-text">${
                                            item.amount || ""
                                          }</td>
                                      </tr>`
                      )
                      .join(
                        ""
                      )}                                                  
                </table>
            </div>
            <div class="box2" style="position: relative; top: 0px; page-break-inside: avoid;">
                <table style=" border-collapse: collapse; border: none;  width: 100%;;" id="toatal-table">
                    <tr class="bg-light fw-600 backgroun-dark">
                        <td class="col-7 py-1" style="width:60%; border: none; border-left: 1px solid #ddd;"></td>
                        <td class="" style="width:40% ;border: none; border-right: 1px solid #ddd; border-left: 1px solid #ddd;">
                            <div class="sub-total">
                                <div class="sub-total-name font-heading pt-5" style="padding-left:5px;">
                                    Sub Total:
                                </div>
                                <div class="sub-total-valuept-5 " style="padding-right:5px;">
                                ${invoiceDetails.subtotal || ""}
                                </div>
                            </div>
                            <span class="float-end"></span>
                        </td>
                    </tr>
                </table>
            </div>
            <table style="border-collapse: collapse; width: 100%; margin: 0; padding: 1px; page-break-inside: avoid ;" id="total-table">
                <tr>
                    <td style="width: 60%; vertical-align: top; padding: 0; border-right: 1px solid #ddd;border-bottom: none; border-top: none; border-collapse: collapse;">
                        <h4 style="margin: 0; padding-left:5px;" class="font-header pt-5">Total In Word</h4>
                        <p style="margin: 0; font-size: 15px; width: 90%;   text-indent: 2em;">
                           Indian Rupee ${amountInWords || ""}  Only </p>
                    </td>
                    <!-- CGST and SGST Section -->
                    <td style="width: 40%; vertical-align: top; padding: 0;">
                        <div style="display: flex; flex-direction: column; justify-content: flex-start; height: 100%; padding-top: 5px;">
                         ${
                           invoiceDetails.adjustment &&
                           invoiceDetails.adjustment != 0 &&
                           invoiceDetails.adjustment != 0.0 &&
                           invoiceDetails.adjustment != "0"
                             ? `
                            <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                       <div class="sub-total-name" style="width: 50%;">${
                                         invoiceDetails.adjustmentLabel ||
                                         "Adjustment"
                                       }:
                                      </div>
                                      <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">₹${
                                        invoiceDetails.adjustment
                                      }</div>
                                      </div> `
                             : ""
                         }
                            ${
                              invoiceDetails.overallDiscount > 0 || 0.0
                                ? `<div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                             <div class="sub-total-name" style="width: 50%;">Discount(${invoiceDetails.totalDiscount} ${invoiceDetails.totalDiscountType})
                            </div>
                            <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">-₹${invoiceDetails.overallDiscount}</div>
                            </div>`
                                : ""
                            }
                            ${
                              invoiceDetails.taxCheck === "YES"
                                ? userDetails.state !== invoiceDetails.state
                                  ? Object.entries(groupedItems1)
                                      .map(([taxRate, items]) => {
                                        let totalIGST = 0.0;
                                        // Loop through items to accumulate IGST
                                        items.forEach((item) => {
                                          const igstValue =
                                            parseFloat(item.igst) || 0.0;
                                          if (item.customerTaxType === 1) {
                                            totalIGST += igstValue * 2;
                                          } else {
                                            totalIGST += igstValue;
                                          }
                                        });
                                        return `
                  <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                      <div class="sub-total-name" style="width: 50%;">IGST (${taxRate}%):
                          </div>
                    <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">${totalIGST.toFixed(
                      2
                    )}</div>
                    </div>`;
                                      })
                                      .join("")
                                  : Object.entries(groupedItems)
                                      .map(([taxRate, items]) => {
                                        let totalCGST = 0.0;
                                        let totalSGST = 0.0;
                                        items.forEach((item) => {
                                          const cgstValue =
                                            parseFloat(item.cgst) || 0.0;
                                          const sgstValue =
                                            parseFloat(item.sgst) || 0.0;

                                          totalCGST += cgstValue;
                                          totalSGST += sgstValue;
                                        });
                                        return `
                  <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                    <div class="sub-total-name" style="width: 50%;">CGST (${
                      taxRate / 2
                    }%):</div>
                    <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">${totalCGST.toFixed(
                      2
                    )}</div>
                      </div>
                     <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                      <div class="sub-total-name" style="width: 50%;">SGST (${
                        taxRate / 2
                      }%):
                        </div>
                      <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">${totalSGST.toFixed(
                        2
                      )}</div>
                      </div> `;
                                      })
                                      .join("")
                                : ""
                            }
                            ${
                              invoiceDetails.value > 0
                                ? invoiceDetails.type === "TDS"
                                  ? `
                                    <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                      <div class="sub-total-name" style="width: 50%;">TDS (${invoiceDetails.tdsTax})</div>
                                      <div class="sub-total-value" style="width: 50%; text-align: right;padding-right:5px;">₹${invoiceDetails.value}</div>
                                    </div><br/>`
                                  : invoiceDetails.type === "TCS"
                                  ? `
                                      <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                        <div class="sub-total-name" style="width: 50%;">TCS (${invoiceDetails.tcsTax})</div>
                                        <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">₹${invoiceDetails.value}</div>
                                      </div><br/>`
                                  : ""
                                : ""
                            }
                        </div>
                    </td>
                </tr>
            </table>
            <table style="border-collapse: collapse; margin-top: 0px; width: 100%; border: none; border-left: 1px solid #ddd; border-radius:1px solid #ddd ;" id="total-table">
                <tr>
                    <td style="width: 60%; border-right: 1px solid #ddd; ">
                        <div style="display: inline-block; font-weight: bold; padding-bottom: 5px; padding-left:3px;" class="font-header pt-5">
                            Note:
                        </div>
                        <div style="display: flex; align-items: start;">
                            <span style="font-size: 14px; padding-left:6px;word-break: break-word; text-indent:2em;">${
                              invoiceDetails.customerNote || ""
                            }
                            </span>
                        </div>
                    </td>
                    <td style="width: 40%; vertical-align: top;  border-right: 1px solid #ddd;" class=" backgroun-dark">
                        <div class="sub-total" style="vertical-align: top;">
                            <div class="sub-total-name font-heading pt-5" style="font-weight: bold; padding-left:5px;">
                                Total:
                            </div>
                            <div class="sub-total-value font-heading pt-5" style="font-size:14px; font-weight: bold; padding-right:5px;">
                                ${
                                  invoiceDetails.total || ""
                                }                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="breakingele"></div>
            ${
              showQrCode
                ? `<table style=" border-collapse: collapse; margin-top: 0px; width: 100%;;" id="toatal-table">
                <tr>
                    <td class="col-7 text-1" style="width: 60%; vertical-align: top; padding: 10px;">
    <div class="bank-detail">
        <div class="bank-detail-main">
            <h4 style="padding-left: 3px; margin: 5px 0px 10px 0px;" class="font-heading">Bank Details</h4>
            <table style="width: 100%; font-size: 12px; border: none; border-collapse: collapse;">
                <tr>
                    <td style="padding-bottom: 3px; width: 120px; border: none;">ACCOUNT NAME:</td>
                    <td style="text-align: left; font-size: 13px; padding-bottom: 3px; border: none;">${
                      bankData.name || ""
                    }</td>
                </tr>
                <tr>
                    <td style="padding-bottom: 3px; width: 120px; border: none;">BANK NAME:</td>
                    <td style="text-align: left; font-size: 13px; padding-bottom: 3px; border: none;">${
                      bankData.bankName || ""
                    }</td>
                </tr>
                <tr>
                    <td style="padding-bottom: 3px; width: 120px; border: none;">ACCOUNT NO:</td>
                    <td style="text-align: left; font-size: 13px; padding-bottom: 3px; border: none;">${
                      bankData.accountNumber || ""
                    }</td>
                </tr>
                <tr>
                    <td style="width: 120px; border: none;">IFSC CODE:</td>
                    <td style="text-align: left; font-size: 13px; border: none;">${
                      bankData.ifsc || ""
                    }</td>
                </tr>
                <tr>
                    <td style="width: 120px; border: none;">Branch Name:</td>
                    <td style="text-align: left; font-size: 13px; border: none;">${
                      bankData.branchName || ""
                    }</td>
                </tr>
            </table>
        </div>
    </div>
</td>
                    <td class="scanner">
                          <div class="">
                          <div class="bank-scanner no-break">
                          <p>SCAN & PAY</p>
                          <img src=${qrImage} alt="QR CODE" class="no-break" height="100" width="auto" />
                          </div>
                            </div>
                    </td>
                </tr>
            </table>`
                : ""
            }
           <table style=" border-collapse: collapse; margin-top:0px; width: 100%; vertical-align:top; break-inside:avoid;" id="toatal-table">
                 <tr>
                     <td class="col-7 text-1" style="width:60%;margin-top:20px;height:100%; vertical-align:top;">
                         <div style="margin:5px">
                             <div class="fw-600 font-heading">Terms &amp; Condition :</div>
                             <div >
                                 <p><span style="font-size: 14px; padding-left:6px;word-break: break-word; text-indent:2em;">${
                                   invoiceDetails.termsAndCondition || ""
                                 }</span></p>
                             </div>
                         </div>
                     </td>
                     <td class="col-5 pe-1 text-end" style="width:40%; margin-top:20px; height:100%; vertical-align: bottom;">
                         <div class="text-end"
                             style="height:120px; display: flex; flex-direction: column; align-items: flex-end;vertical-align: bottom ; justify-content: flex-end;margin:5px">
                             For, ${companyName}
                             <div class="text-1 fst-italic mt-5">(Authorised Signatory)</div>
                         </div>
                     </td>
                 </tr>
             </table>                
        </div>
</body>
</html>      
        `);
      printWindow.document.close();
      printWindow.onload = () => {
        printWindow.onafterprint = () => {
          printWindow.close();
          printWindow.onafterprint = null;
        };
        printWindow.print();
      };
    }
  };

  const handlePrint2 = () => {
    const printableContent = document.getElementById("printable-content");
    if (printableContent) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <!DOCTYPE html>
          <html lang="en">
          <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>${invoiceDetails.invoiceNumber}</title>
          <style>
            @page{
              margin:0px;
                }
        body {
            font-family: Arial, sans-serif;            
            width: 100vw;
            margin: 0 auto;
            background-color: #f8f8f8;            
        }
        *{
            margin: 0px ;
            padding: 0px;
            box-sizing: border-box;
        }
        .invoice {
            background-color: white;
            padding: 20px;
            border: 1px solid #ddd;           
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        h1 {
            font-size: 13px;
            text-align: center;
            margin-bottom: 5px;
        }
        .header {
            text-align: center;
            font-size: 11px;  
        }
        .header p {
            margin: 2px 0;
        }
        .invoice-details {
            display: flex;
            margin-bottom: 15px;
            font-size: 12px;
            width: 100%;
            justify-content: space-between;
        } 
        .invoice-details strong {
            font-weight: bold;
        }
        hr {
            border-top: 1px dashed #000;
            margin: 8px 0;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            font-size: 12px;
            text-align: left;
        }
        table th, table td {
           
        }
        table th {
            margin: 10px 0;
            border-bottom: 1px dashed #000;
            text-align: left;
        }
        table td:first-child {
            text-align: left;
        }
        .totals {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            margin-top: 10px;
        }
        .tot-left{
            text-align: left;
        }
        .tot-right{
            text-align: right;
        }
        .totals p {
            margin: 5px 0;
            font-size: 12px;
        }
        .totals .total {
            font-size: 16px;
            font-weight: bold;
        }
        .thank-you {
            text-align: center;
            margin-top: 20px;
            font-size: 14px;
        }
        #sup-inv{
            padding: 10px 0px;
            font-size: 15px;
        }
        thead{
        }
        #fir-th{
            width: 5%;
            padding-bottom: 10px;
        }
        #sec-th{
            width: 45%;
            padding-bottom: 10px;
        }
        #thi-th{
            width: 10%;
            padding-bottom: 10px;
            padding-left-3px;
        }
        #fou-th{
            width:18%;
            padding-bottom: 10px;
              padding-left:3px;
        }#fif-th{
            padding-bottom: 10px;
            width: 20%;
            text-align: right   ;
        }
        #six-th{
            padding-bottom: 10px;
            width: 20%;
           padding-left-3px;
        }
        .fir-td{
            vertical-align: top;
            text-align: left;
            padding-top: 5px;
        }
        .sec-td{
            padding-top: 5px;
            text-align: left;
        }
        .thi-td{
            vertical-align: bottom;
            padding-top: 5px;
            padding-left-3px;   
        }
        .fou-td{
            vertical-align: bottom;
            padding-top: 5px;
            padding-left:3px;
        }.fif-td{
            vertical-align: bottom;
            padding-top: 5px;
            text-align: right;
        }        
        .six-td{
            vertical-align: bottom;
            padding-top: 5px;
            padding-left-3px;
        }        
        .total-section{
            display: flex;
            justify-content: space-between;
        }
      .comp-data{ font-size: 12px; }
        .comp-name{
        font-weight: bold;
         font-size: 12px;
      }
      .comp-gst{
          font-weight: bold;
      }
      .term-con{
      display:flex;
      width:100%;
      }
      .term-head {
      width:50%;
      vertical-align:top;
      }
      .term-desc{
      width:50%
      }
    </style>
</head>
<body>
    <div class="invoice">
        <h1>${companyName || ""}</h1>
        <div class="header">
            <address>
                 ${userDetails.address1 || ""} ${
        userDetails.address2 || ""
      }<br>${userDetails.zipcode || ""} ${userDetails.city || ""}
                 <br>${userDetails.state || ""} <br>
                 ${
                   userDetails.contact
                     ? `MOB:${userDetails.contact || ""}<br>`
                     : ""
                 } 
                 ${
                   invoiceDetails.gstNumber
                     ? `GST NO: ${invoiceDetails.gstNumber}`
                     : ""
                 }
           </address>
            <p id="sup-inv">SUPPLY INVOICE</p>
        </div>
        <div class="invoice-details">
            <p>Invoice#: ${invoiceDetails.invoiceNumber}</p>
            <p>Date: ${invoiceDetails.invoiceDate}</p>
        </div>
        <hr>    
        <div class="comp-data">
        <p class="comp-name">${invoiceDetails.customerName || ""}  </p>
        <p class="comp-add"> ${
          customerData.billAddress1 ? `${customerData.billAddress1}` : ""
        }</p>
        <p class="comp-city">${
          customerData.billCity || customerData.billZipcode
            ? ` ${customerData.billCity ? `${customerData.billCity} ` : ""} ${
                customerData.billZipcode ? `${customerData.billZipcode}` : ""
              }`
            : ""
        }</p>
        <p class="comp-state-contry">${
          invoiceDetails.state || customerData.billCountry
            ? `  ${invoiceDetails.state ? `${invoiceDetails.state} ` : ""}  ${
                customerData.billCountry ? `${customerData.billCountry}` : ""
              }`
            : ""
        }</p>
        ${
          customerData.mobile
            ? `<p class="mob"> <span class="mob-bold"> MOB :<span> ${customerData.mobile}</p>`
            : ""
        }
        ${
          customerData.gstin
            ? `<p class="gst"> <span class="gst-bold"> GST NO :<span> ${customerData.gstin}</p>`
            : ""
        }
        </div>
        <hr>
        <table>
            <thead>
                <tr>
                    <th id="fir-th">#</th>
                    <th id="sec-th">Item</th>
                    <th id="thi-th">Qty</th>
                    <th id="fou-th">Rate</th>
                    ${
                      invoiceDetails.discountCheck === "YES"
                        ? `<th id="six-th">Discount</th>`
                        : ""
                    }
                    <th id="fif-th">Amount</th>          
                </tr>
            </thead>
            <tbody>
            ${itemDetails
              .map(
                (item, index) => `
                <tr>
                    <td class="fir-td">${index + 1}</td>
                    <td class="sec-td">${item.itemsDetails}</td>
                    <td class="thi-td">${item.quantity}</td>
                    <td class="fou-td">${item.rate}</td>
                    ${
                      invoiceDetails.discountCheck === "YES"
                        ? `<td class="six-td">${item.discount || ""} (${
                            item.discountType || ""
                          })</td>`
                        : ""
                    }
                    <td class="fif-td">${item.amount}</td>
                </tr>`
              )
              .join("")}
            </tbody>
        </table>
        <hr>
        <div class="totals">
            <div class="tot-left">
                <p>Sub Total</p>
                 ${
                   invoiceDetails.taxCheck === "YES"
                     ? userDetails.state !== invoiceDetails.state
                       ? Object.entries(groupedItems1)
                           .map(([taxRate, items]) => {
                             let totalIGST = 0.0;
                             items.forEach((item) => {
                               const igstValue = parseFloat(item.igst) || 0.0;
                               if (item.customerTaxType === 1) {
                                 totalIGST += igstValue * 2;
                               } else {
                                 totalIGST += igstValue;
                               }
                             });
                             return `
                <p>IGST (${taxRate}%)</p>`;
                           })
                           .join("")
                       : Object.entries(groupedItems)
                           .map(([taxRate, items]) => {
                             let totalCGST = 0.0;
                             let totalSGST = 0.0;
                             items.forEach((item) => {
                               const cgstValue = parseFloat(item.cgst) || 0.0;
                               const sgstValue = parseFloat(item.sgst) || 0.0;

                               totalCGST += cgstValue;
                               totalSGST += sgstValue;
                             });
                             return `
                               <p>SGST (${taxRate / 2}%)</p>   
                               <p>CGST (${taxRate / 2}%)</p>    
                                              `;
                           })
                           .join("")
                     : ""
                 }   
          ${
            invoiceDetails.adjustment &&
            invoiceDetails.adjustment != 0 &&
            invoiceDetails.adjustment != 0.0 &&
            invoiceDetails.adjustment != "0"
              ? `<p>${invoiceDetails.adjustmentLabel || "Adjustment"}:</p> `
              : ""
          }    
          ${
            invoiceDetails.value > 0
              ? invoiceDetails.type === "TDS"
                ? `<p>TDS (${invoiceDetails.tdsTax})</p>`
                : invoiceDetails.type === "TCS"
                ? `<p>TCS (${invoiceDetails.tcsTax})</p>`
                : ""
              : ""
          }
            </div>
            <div class="tot-right">
                <p>₹${invoiceDetails.subtotal || ""}</p>
                ${
                  invoiceDetails.taxCheck === "YES"
                    ? userDetails.state !== invoiceDetails.state
                      ? Object.entries(groupedItems1)
                          .map(([taxRate, items]) => {
                            let totalIGST = 0.0;
                            // Loop through items to accumulate IGST
                            items.forEach((item) => {
                              const igstValue = parseFloat(item.igst) || 0.0;
                              if (item.customerTaxType === 1) {
                                totalIGST += igstValue * 2;
                              } else {
                                totalIGST += igstValue;
                              }
                            });
                            return `
                       <p>₹${totalIGST.toFixed(2)}</p>`;
                          })
                          .join("")
                      : Object.entries(groupedItems)
                          .map(([taxRate, items]) => {
                            let totalCGST = 0.0;
                            let totalSGST = 0.0;
                            items.forEach((item) => {
                              const cgstValue = parseFloat(item.cgst) || 0.0;
                              const sgstValue = parseFloat(item.sgst) || 0.0;
                              totalCGST += cgstValue;
                              totalSGST += sgstValue;
                            });
                            return `
                                      <p>₹${totalCGST.toFixed(2)}</p>   
                                      <p>₹${totalSGST.toFixed(2)}</p> `;
                          })
                          .join("")
                    : ""
                }
                       ${
                         invoiceDetails.adjustment &&
                         invoiceDetails.adjustment != 0 &&
                         invoiceDetails.adjustment != 0.0 &&
                         invoiceDetails.adjustment != "0"
                           ? `
                       <p>₹ ${invoiceDetails.adjustment || ""}</p>`
                           : ""
                       }
                        ${
                          invoiceDetails.value > 0
                            ? invoiceDetails.type === "TDS"
                              ? `<p>₹${invoiceDetails.value}</p>`
                              : invoiceDetails.type === "TCS"
                              ? `<p>₹${invoiceDetails.value}</p>`
                              : ""
                            : ""
                        }
            </div>
        </div>
        <hr style="border: 1px solid black;">
        <div class="total-section">
                <div class="tot-sec-left">
                    <p class="total"><strong> TOTAL</strong></p>
                </div>
                <div class="tot-sec-right">
                <p class="total" ><strong>₹${
                  invoiceDetails.total || ""
                }</strong></p>
                </div>                
            </div>
            <hr style="border: 1px solid black;">
            ${
              invoiceDetails.termsAndCondition
                ? `
            <div class="term-con">
            <div class="term-head">Terms and condition :</div>
            <div class="term-desc">${
              invoiceDetails.termsAndCondition || ""
            }</div>
            </div>`
                : ""
            }

        <div class="thank-you">
            <p>Thank you!</p>
        </div>
    </div>
</body>
</html>      
         `);
      printWindow.document.close();
      printWindow.onload = () => {
        printWindow.onafterprint = () => {
          printWindow.close();
          printWindow.onafterprint = null;
        };
        printWindow.print();
      };
    }
  };

  return (
    <>
      {detailsPage && (
        <>
          <div
            className="d-flex justify-content-between mt-3"
            style={{ padding: "5px 5px 0px 5px" }}
          >
            <div className="d-flex align-items-center ">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <div className="headinvoice ml-2">
                <h5
                  className="ml-1 mb-0 text-primary"
                  style={{ letterSpacing: "1px" }}
                >
                  All Details
                </h5>
                <p className="ml-1 mb-0 text-muted">{invoiceCount} invoices</p>
              </div>
            </div>
            <div className="add-invoice pr-2 d-flex align-items-baseline"></div>
          </div>

          <div
            className="card border-2 invoice-border mt-3"
            style={{
              borderRadius: "20px",
              height: "auto",
              marginBottom: "0px",
            }}
          >
            <div className="custom-templates-container">
              {/* Left Sidebar */}
              <div className="left-invoice custom-scrollbar">
                <div>
                  <div
                    className="text-left custom-scrollbar"
                    style={{ cursor: "pointer" }}
                  >
                    {invoices.map((invoice, index) => (
                      <React.Fragment key={index}>
                        <div
                          className="invoice-row"
                          onClick={() => {
                            handleInvoiceClick(invoice.invoiceNumber);
                            fetchOffline(invoice.customerId);
                            getCustomerData(invoice.customerId);
                            setShowDetails(true);
                            setOfflinePay(false);
                          }}
                        >
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              marginLeft: "10px",
                              marginBottom: "1px",
                            }}
                          >
                            {invoice.customerName}
                          </p>

                          <p
                            className="text-muted mt-1"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              marginLeft: "10px",
                              marginBottom: "0px",
                            }}
                          >
                            {invoice.invoiceNumber}
                          </p>
                          <div className="d-flex justify-content-between mt-1">
                            <p
                              className=""
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                marginLeft: "10px",
                                marginBottom: "0px",
                              }}
                            >
                              {`₹ ${invoice.total}`}
                            </p>
                            <span className="fs-1 text-muted">
                              {invoice.dueDate}
                            </span>
                          </div>
                          <p
                            className=""
                            style={{
                              color: invoice.status.startsWith("O")
                                ? "red"
                                : invoice.status.startsWith("D")
                                ? "#408dfb"
                                : "green",
                              marginLeft: "10px",
                              marginBottom: "0px",
                              marginTop: "10px",
                            }}
                          >
                            {invoice.status}
                          </p>
                        </div>
                        {index < invoices.length - 0 && (
                          <div className="horizontal-line"></div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
              <div className="middle-line"></div>
              {/* Right Side Content */}
              {showDetails && (
                <div className="right-invoice">
                  {/* Top Template Bar */}
                  <ul
                    className="nav nav-tabs-new d-flex"
                    style={{ padding: "0px 0px 10px 0px" }}
                  >
                    <h6
                      className="flex-grow-1 mb-0 ml-1"
                      style={{ color: "#3C7BD4" }}
                    >
                      {invoiceDetails.invoiceNumber}
                    </h6>
                    <li className="nav-item mr-1 cursor-pointer">
                      <a
                        className="nav-link"
                        href="#"
                        role="button"
                        onClick={() =>
                          handleEditClick(invoiceDetails.invoiceNumber)
                        }
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <CiEdit size={18} />
                        <span>Edit</span>{" "}
                      </a>
                    </li>
                    <li className="nav-item mr-1">
                      <a
                        className="nav-link "
                        onClick={() =>
                          handleMailClick(invoiceDetails.invoiceNumber)
                        }
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <CiMail size={18} />
                        <span>Mail</span>{" "}
                      </a>
                    </li>
                    <li className="nav-item" id="printable-content">
                      <a
                        className="nav-link "
                        onClick={handlePrint}
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <MdOutlinePrint size={18} />
                        <span>Print</span>{" "}
                      </a>
                    </li>

                    <li className="nav-item ml-1" id="printable-content">
                      <a
                        className="nav-link "
                        onClick={handlePrint2}
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <MdOutlinePrint size={18} />
                        <span>Print Receipt</span>{" "}
                      </a>
                    </li>

                    <li className="nav-item ml-1" id="printable-content">
                      <a
                        className="nav-link "
                        onClick={showPDF}
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                        title="Download PDF"
                      >
                        <MdPictureAsPdf size={18} />
                        <span>PDF</span>{" "}
                      </a>
                    </li>
                  </ul>

                  <div className="horizontal-line"></div>

                  {/* Section Below Top Template Bar */}
                  <div className="custom-container">
                    <div
                      className="section"
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        className="print-section border border-2"
                        style={{
                          position: "relative",
                          zIndex: 1, // Ensure this section is above other content
                        }}
                      >
                        {invoiceDetails.status !== "Draft" && (
                          <div className="top-template-bar1">
                            <div className="top-bar-icons">
                              Rs
                              <span className="ml-2">
                                Get paid faster with online payment gateways{" "}
                              </span>
                            </div>
                            {invoiceDetails.receivables === "0.00" ||
                            invoiceDetails.receivables === "0" ? (
                              <div className="top-bar-icons">
                                <button
                                  className="btn "
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                  }}
                                >
                                  Payment Done
                                </button>
                              </div>
                            ) : (
                              <div className="top-bar-icons">
                                <button
                                  className="btn btn-success"
                                  onClick={() => {
                                    setOfflinePay(true);
                                    setShowDetails(false);
                                    fetchOffline();
                                  }}
                                >
                                  Offline Payment
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {/*****Invoice*****/}
                    <div className="section1 custom-scrollbar invoice-data m-0">
                      <div className="invoice-container">
                        <div className="invoice-head border-bottom-0">
                          <div className="invoice-head-top">
                            {userDetails && (
                              <div className="invoice-head-top-left text-start p-2">
                                <ul className="text-start mb-0">
                                  {companyName && (
                                    <li className="text-bold">{companyName}</li>
                                  )}
                                  {userDetails.address1 && (
                                    <li>{userDetails.address1}</li>
                                  )}
                                  {userDetails.address2 && (
                                    <li>{userDetails.address2}</li>
                                  )}
                                  {userDetails.zipcode && (
                                    <li>{userDetails.zipcode}</li>
                                  )}
                                  {userDetails.city && (
                                    <li>{userDetails.city}</li>
                                  )}
                                  {userDetails.state && (
                                    <li>{userDetails.state}</li>
                                  )}
                                  {userDetails.businessLocation && (
                                    <li>{userDetails.businessLocation}</li>
                                  )}
                                  {userDetails.contact && (
                                    <li>
                                      <span className="text-bold">MOB :</span>{" "}
                                      {userDetails.contact}
                                    </li>
                                  )}
                                  {invoiceDetails.gstNumber && (
                                    <li>
                                      <span className="text-bold">GST NO:</span>{" "}
                                      {invoiceDetails.gstNumber}
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                            <div className="invoice-head-top-right text-end p-2 d-flex align-items-end justify-content-end">
                              <h3 className="mb-0">TAX INVOICE</h3>
                            </div>
                          </div>
                          <div
                            className="hr"
                            style={{ border: "1px solid #9e9e9e" }}
                          ></div>
                          <div className="invoice-head-bottom p-0">
                            <div className="invoice-head-bottom-left right-border p-2">
                              <ul>
                                <div className="row">
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      <b>Invoice No </b>
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      : {invoiceDetails.invoiceNumber}
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      <b>Invoice Date </b>
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      : {invoiceDetails.invoiceDate}
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      <b>Terms</b>
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      : {invoiceDetails.terms}
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li>
                                      <b>Due Date</b>
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li>: {invoiceDetails.dueDate}</li>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                          <div
                            className="hr"
                            style={{ border: "1px solid #9e9e9e" }}
                          ></div>
                          <div
                            className="invoice-head-middle"
                            style={{ backgroundColor: "#f2f3f4" }}
                          >
                            <div className="invoice-head-middle-left text-start pl-2">
                              <p className="mb-0">
                                <span className="text-bold">Bill To: </span>
                              </p>
                            </div>
                          </div>
                          <div
                            className="hr"
                            style={{ border: "1px solid #9e9e9e" }}
                          ></div>
                          <div className="invoice-head-middle">
                            <div className="invoice-head-middle-left text-start pl-2">
                              <p className="mb-0">
                                {invoiceDetails.customerName && (
                                  <span className="text-primary">
                                    {invoiceDetails.customerName}
                                  </span>
                                )}
                                {customerData.billAddress1 && (
                                  <>
                                    <br />
                                    {customerData.billAddress1}
                                  </>
                                )}
                                {customerData.billCity &&
                                  customerData.billZipcode && (
                                    <>
                                      <br />
                                      {customerData.billCity}{" "}
                                      {customerData.billZipcode}
                                    </>
                                  )}
                                {invoiceDetails.state && (
                                  <>
                                    <br />
                                    {invoiceDetails.state}
                                  </>
                                )}
                                {customerData.billCountry && (
                                  <>
                                    <br />
                                    {customerData.billCountry}
                                  </>
                                )}
                                {customerData.mobile && (
                                  <>
                                    <br />
                                    {customerData.mobile}
                                  </>
                                )}
                                {customerData.gstin && (
                                  <>
                                    <br />
                                    <span className="text-bold">
                                      GST No:
                                    </span>{" "}
                                    {customerData.gstin}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                          <div
                            className="hr"
                            style={{ border: "1px solid #9e9e9e" }}
                          ></div>
                        </div>
                        <div className="overflow-view">
                          <div className="invoice-body">
                            <table>
                              <thead>
                                <tr>
                                  <td
                                    className="td-left-border text-bold"
                                    style={{ border: "0px" }}
                                  >
                                    ID
                                  </td>
                                  <td className="text-bold">
                                    Items & Description
                                  </td>
                                  {invoiceDetails.hsnCheck === "YES" && (
                                    <td className="text-bold">HSN</td>
                                  )}
                                  {invoiceDetails.sizeCheck === "YES" && (
                                    <td className="text-bold">Size</td>
                                  )}
                                  <td className="text-bold">QTY</td>
                                  <td className="text-bold">Rate</td>
                                  {invoiceDetails.discountCheck === "YES" && (
                                    <td className="text-bold">Discount</td>
                                  )}
                                  {invoiceDetails.taxCheck === "YES" && (
                                    <td className="text-bold">Tax</td>
                                  )}
                                  <td className="text-bold">Amount</td>
                                </tr>
                              </thead>
                              <tbody>
                                {itemDetails.map((item, index) => (
                                  <tr key={index}>
                                    <td style={{ border: "0px" }}>{i++}</td>
                                    <td style={{ whiteSpace: "pre-line" }}>
                                      {item.itemsDetails}
                                      <br />
                                      {item.description2}
                                    </td>
                                    {invoiceDetails.hsnCheck === "YES" && (
                                      <td>{item.hsnSac}</td>
                                    )}
                                    {invoiceDetails.sizeCheck === "YES" && (
                                      <td>{item.size}</td>
                                    )}
                                    <td>{item.quantity}</td>
                                    <td>{item.rate}</td>
                                    {invoiceDetails.discountCheck === "YES" && (
                                      <td>
                                        {item.discount || "0"} (
                                        {item.discountType})
                                      </td>
                                    )}
                                    {invoiceDetails.taxCheck === "YES" && (
                                      <td>{item.tax || "0"}%</td>
                                    )}
                                    <td>{item.amount}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div style={{ width: "100%", marginTop: "1px" }}>
                              <div
                                style={{
                                  width: "50%",
                                  padding: "4px 4px 3px 7px",
                                  float: "left",
                                }}
                              >
                                <div style={{ margin: "10px 0 5px" }}>
                                  <div style={{ paddingRight: "10px" }}>
                                    Total In Words
                                  </div>
                                  <span>
                                    <b>
                                      <i>Indian Rupee {amountInWords} Only</i>
                                    </b>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    paddingTop: "15px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      wordWrap: "break-word",
                                    }}
                                    className="pcs-notes"
                                  >
                                    <h6>Terms And Condition:</h6>
                                    <div
                                      className="pcs-notes"
                                      style={{
                                        whiteSpace: "pre-wrap",
                                        wordWrap: "break-word",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          invoiceDetails.termsAndCondition,
                                      }}
                                    />
                                  </p>
                                </div>
                                {showQrCode && (
                                  <div>
                                    <span
                                      style={{
                                        marginTop: "50px",
                                        font: "bold",
                                      }}
                                    >
                                      Scan to Pay
                                    </span>
                                    <div
                                      ref={qrRef}
                                      style={{
                                        width: "80%",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {" "}
                                      <QRCode value={paymentURL} size={100} />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                style={{ width: "43.6%", float: "right" }}
                                className="pcs-totals"
                              >
                                <table
                                  className="left-border"
                                  id="itemTable"
                                  cellSpacing="0"
                                  border="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td valign="middle">Sub Total</td>
                                      <td
                                        id="tmp_subtotal"
                                        valign="middle"
                                        style={{ width: "110px" }}
                                      >
                                        ₹{invoiceDetails.subtotal}
                                      </td>
                                    </tr>
                                    {invoiceDetails.adjustment !== 0 &&
                                    invoiceDetails.adjustment !== "0" &&
                                    invoiceDetails.adjustment !== "0.0" ? (
                                      <tr>
                                        <td valign="middle">
                                          {invoiceDetails.adjustmentLabel ||
                                            "Adjustment"}
                                        </td>
                                        <td
                                          id="tmp_total"
                                          valign="middle"
                                          style={{ width: "110px" }}
                                        >
                                          ₹{invoiceDetails.adjustment}
                                        </td>
                                      </tr>
                                    ) : (
                                      ""
                                    )}
                                    {invoiceDetails.overallDiscount !== 0 ||
                                      (0.0 && (
                                        <tr>
                                          <td valign="middle">
                                            Discount(
                                            {invoiceDetails.totalDiscount}
                                            {invoiceDetails.totalDiscountType})
                                          </td>
                                          <td
                                            id="tmp_total"
                                            valign="middle"
                                            style={{ width: "110px" }}
                                          >
                                            - ₹{invoiceDetails.overallDiscount}
                                          </td>
                                        </tr>
                                      ))}

                                    {invoiceDetails.taxCheck === "YES" &&
                                      (userDetails.state !==
                                      invoiceDetails.state
                                        ? Object.entries(groupedItems1).map(
                                            ([taxRate, items]) => {
                                              let totalIGST = 0.0;

                                              // Loop through items to accumulate IGST
                                              items.forEach((item) => {
                                                const igstValue =
                                                  parseFloat(item.igst) || 0.0;
                                                if (
                                                  item.customerTaxType === 1
                                                ) {
                                                  totalIGST += igstValue * 2;
                                                } else {
                                                  totalIGST += igstValue;
                                                }
                                              });

                                              return (
                                                <React.Fragment key={taxRate}>
                                                  <tr>
                                                    <td className="headings">
                                                      IGST ({taxRate}%):
                                                    </td>
                                                    <td className="headings">
                                                      {totalIGST.toFixed(2)}
                                                    </td>
                                                  </tr>
                                                  {/* <tr>
                                                <td className="headings">IGST ({taxRate}%):</td>
                                                <td className="headings">{totalIGST.toFixed(2)}</td>
                                              </tr> */}
                                                </React.Fragment>
                                              );
                                            }
                                          )
                                        : Object.entries(groupedItems).map(
                                            ([taxRate, items]) => {
                                              let totalCGST = 0.0;
                                              let totalSGST = 0.0;

                                              items.forEach((item) => {
                                                const cgstValue =
                                                  parseFloat(item.cgst) || 0.0;
                                                const sgstValue =
                                                  parseFloat(item.sgst) || 0.0;

                                                totalCGST += cgstValue;
                                                totalSGST += sgstValue;
                                              });

                                              return (
                                                <React.Fragment key={taxRate}>
                                                  <tr>
                                                    <td className="headings">
                                                      CGST ({taxRate / 2}%):
                                                    </td>
                                                    <td className="headings">
                                                      {totalCGST.toFixed(2)}
                                                    </td>
                                                  </tr>
                                                  {/* <tr>
                                                <td className="headings">CGST ({taxRate / 2}%):</td>
                                                <td className="headings">{totalCGST.toFixed(2)}</td>
                                              </tr>
                                              <tr>
                                                <td className="headings">SGST ({taxRate / 2}%):</td>
                                                <td className="headings">{totalSGST.toFixed(2)}</td>
                                              </tr> */}
                                                  <tr>
                                                    <td className="headings">
                                                      SGST ({taxRate / 2}%):
                                                    </td>
                                                    <td className="headings">
                                                      {totalSGST.toFixed(2)}
                                                    </td>
                                                  </tr>
                                                </React.Fragment>
                                              );
                                            }
                                          ))}
                                    {invoiceDetails.value > 0 &&
                                      (invoiceDetails.type === "TDS" ? (
                                        <tr>
                                          <td valign="middle">
                                            TDS({invoiceDetails.tdsTax})
                                          </td>
                                          <td
                                            id="tmp_total"
                                            valign="middle"
                                            style={{ width: "110px" }}
                                          >
                                            ₹{invoiceDetails.value}
                                          </td>
                                        </tr>
                                      ) : (
                                        <tr>
                                          <td valign="middle">
                                            TCS({invoiceDetails.tcsTax})
                                          </td>
                                          <td
                                            id="tmp_total"
                                            valign="middle"
                                            style={{ width: "110px" }}
                                          >
                                            ₹{invoiceDetails.value}
                                          </td>
                                        </tr>
                                      ))}
                                    <tr style={{ height: "10px" }}>
                                      <td valign="middle">Total</td>
                                      <td
                                        valign="middle"
                                        style={{ width: "110px" }}
                                      >
                                        ₹{invoiceDetails.total}
                                      </td>
                                    </tr>
                                    <tr
                                      style={{ height: "10px" }}
                                      className="pcs-balance"
                                    >
                                      <td valign="middle">
                                        <b>Balance Due</b>
                                      </td>
                                      <td
                                        id="tmp_balance_due"
                                        valign="middle"
                                        style={{ width: "110px", color: "red" }}
                                      >
                                        <strong>
                                          ₹{invoiceDetails.receivables}
                                        </strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          paddingTop: "5px",
                                        }}
                                        colSpan="2"
                                      >
                                        <div
                                          style={{ minHeight: "75px" }}
                                        ></div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan="2"
                                      >
                                        <label
                                          style={{ marginBottom: "0px" }}
                                          className="pcs-totals"
                                        >
                                          Authorized Signature
                                        </label>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div style={{ clear: "both" }}></div>
                              <div style={{ clear: "both" }}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showOfflinePay && (
                <div className="right-invoice">
                  <h5>
                    Payment for :{" "}
                    <span style={{ color: "#3C7BD4" }}>
                      {invoiceDetails.invoiceNumber}{" "}
                    </span>
                  </h5>
                  <div className="custom-container">
                    <div
                      className="section1 custom-scrollbar invoice-data m-0 p-3"
                      style={{ maxHeight: "700px", overflowX: "hidden" }}
                    >
                      <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-6">
                          <label
                            className="form-label required mg-b-0"
                            style={font}
                          >
                            Customer Name*
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label
                            className="form-label required mg-b-0"
                            style={font}
                          >
                            Payment #*
                          </label>
                        </div>
                      </div>
                      <div className="row mg-t-8 align-items-center mg-b-20">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            placeholder="Customer Name"
                            name="customerName"
                            type="text"
                            value={invoiceDetails.customerName}
                            readOnly
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            placeholder="Payment"
                            name="Payment"
                            type="text"
                            value={formData.paymentCount}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="container my-2 border border-2 payment-gateway">
                        <div className="row pt-3 align-items-center mg-b-20">
                          {/* <!-- Bottom Left Section --> */}
                          <div className="col-md-5 ">
                            <label className="form-label required" style={font}>
                              Amount Received(INR)
                            </label>
                          </div>

                          <div className="col-md-6 ">
                            <input
                              className="form-control"
                              placeholder="Amount Received(INR)"
                              name="amountReceived"
                              type="text"
                              value={formData.amountReceived}
                              onChange={handleChange}
                            />{" "}
                          </div>
                        </div>

                        <div className="row pt-3 align-items-center mg-b-20">
                          {/* <!-- Bottom Left Section --> */}
                          <div className="col-md-5 ">
                            <label className="form-label required">
                              Bank Charges (if any)
                            </label>
                          </div>

                          <div className="col-md-6 ">
                            <input
                              className="form-control"
                              placeholder="Bank Charges (if any)"
                              name="bankCharges"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                        <hr />

                        <div className="row pt-3 align-items-center mg-b-20">
                          {/* <!-- Bottom Left Section --> */}
                          <div className="col-md-5">
                            <label className="form-label required">
                              Tax deducted?
                            </label>
                          </div>

                          <div className="col-md-7">
                            <label className="fancy-radio custom-color-green">
                              <input
                                name="taxDeducated"
                                id="tax"
                                value=""
                                type="radio"
                                onClick={handleTaxDeducted}
                              />
                              <span>
                                <i></i>No Tax deducted
                              </span>
                            </label>

                            <label className="fancy-radio custom-color-green">
                              <input
                                name="taxDeducated"
                                id="tax2"
                                value=""
                                type="radio"
                                onClick={handleBankSelect}
                              />
                              <span>
                                <i></i>Yes, TDS (Income Tax)
                              </span>
                            </label>
                          </div>
                        </div>

                        {showTaxDeducted && (
                          <div className="row pt-3 align-items-center mg-b-20">
                            {/* <!-- Bottom Left Section --> */}
                            <div className="col-md-5 ">
                              <label
                                className="form-label required"
                                style={font}
                              >
                                TDS Tax Account*
                              </label>
                            </div>

                            <div className="col-md-6 ">
                              <input
                                className="form-control"
                                placeholder="TDS Tax Account"
                                name="tdsTaxAccount"
                                type="text"
                              />{" "}
                            </div>
                          </div>
                        )}
                        {showTaxDeducted && (
                          <div
                            className="row pt-3 align-items-center mg-b-20"
                            style={font}
                          >
                            {/* <!-- Bottom Left Section --> */}
                            <div className="col-md-5 ">
                              <label className="form-label required">
                                Amount Withheld*
                              </label>
                            </div>

                            <div className="col-md-6 ">
                              <input
                                className="form-control"
                                placeholder="Amount Withheld"
                                name="amountWithheld"
                                type="text"
                              />{" "}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-4">
                          <label
                            className="form-label required mg-b-0"
                            style={font}
                          >
                            Payment Date*
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label className="form-label required mg-b-0">
                            Payment Mode
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label
                            className="form-label required mg-b-0"
                            style={font}
                          >
                            Deposit To*
                          </label>
                        </div>
                      </div>
                      <div className="row mg-t-8 align-items-center mg-b-20">
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="date"
                            value={currentDate}
                            onChange={(e) => setCurrentDate(e.target.value)}
                          />
                        </div>
                        <div className="col-md-4">
                          <select
                            className="form-control"
                            name="paymentMode"
                            value={formData.paymentMode}
                            onChange={handleChange}
                          >
                            <option>Select Payment Mode</option>
                            <option value="cash">Cash</option>
                            <option value="Bank Remittance">
                              Bank Remittance
                            </option>
                            <option value="Bank Transfer">Bank Transfer</option>
                            <option value="Cheque">Cheque</option>
                            <option value="Credit Card">Credit card</option>
                            <option value="Bank Account">Bank Account</option>
                            <option value="Online">Online</option>
                            <option value="Finance">Finance</option>
                          </select>
                        </div>
                        <div className="col-md-4">
                          <select
                            className="form-control"
                            name="depositeTo"
                            value={formData.depositeTo}
                            onChange={handleChange}
                          >
                            <option>Select Deposit</option>
                            <option value="Bank">Bank</option>
                            <option value="Petty Cash">Petty Cash</option>
                            <option value="Undeposited Fund">
                              Undeposited Fund
                            </option>
                            <option value="Employee Reimbursements">
                              Employee Reimbursements
                            </option>
                            <option value="Opening Balance Adjustment">
                              Opening Balance Adjustment
                            </option>
                            <option value="TDS Payable">TDS Payable</option>
                          </select>
                        </div>
                      </div>

                      <div className="row mg-t-8 pt-3 align-items-center mg-b-20">
                        <div className="col-md-6">
                          <label className="form-label required mg-b-0">
                            Reference #*
                          </label>
                        </div>
                      </div>

                      <div className="row mg-t-8 align-items-center mg-b-20">
                        <div className="col-md-12">
                          <input
                            className="form-control"
                            placeholder="reference"
                            name="reference"
                            type="text"
                            value={formData.reference}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      {/* <div className="row mg-t-8 pt-3 align-items-center mg-b-20">
                        <div className="col-md-6">
                          <label className="form-label required mg-b-0">
                            Notes
                          </label>
                        </div>
                      </div> */}

                      {/* <div className="row mg-t-8 align-items-center mg-b-20">
                        <div className="col-md-12">
                          <textarea
                            className="form-control"
                            placeholder="Notes"
                            name="note"
                            type="text"
                            value={formData.note}
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}

                      <div className="row mg-t-8 pt-3 align-items-center mg-b-20">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-10">
                              {/* <label
                                className="fancy-checkbox mb-0"
                                style={emailStyle}
                              >
                                <input type="checkbox" name="checkbox" />
                                <span className="pl-2">
                                  Email a "thank you" note for this payment
                                </span>
                              </label> */}
                            </div>

                            {/* <div className="mg-t-8 pt-3 align-items-center mg-b-20"> */}
                            <div className="col-md-3 ml-2">
                              <button
                                className="btn btn-primary"
                                onClick={insertPayment}
                              >
                                Record Payment
                              </button>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {invoiceEdit && (
        <InvoiceUpdate
          invoiceNumber1={invoiceNumber}
          handleCancel={handleCancelUpdate}
        />
      )}

      {openMail && (
        <>
          <div className="d-flex align-items-center ml-1 mt-4">
            <div
              className="cursor-pointer d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                height: "35px",
                width: "38px",
                cursor: "pointer",
              }}
            >
              <ArrowBackIosIcon
                width={2}
                onClick={handleCloseMailClick}
                className="arrow-child"
              />
            </div>
            <div className="headitems ml-2">
              <h5
                className="ml-1 mb-0"
                style={{ letterSpacing: "1px", color: "#086bd5de" }}
              >
                Mail
              </h5>
            </div>
          </div>
          <div
            className="profit-loss-section my-3 mx-0"
            style={{
              padding: "30px",
              backgroundColor: "#fff",
              borderRadius: "9px",
            }}
          >
            <br />
            <div className="radio row mg-t-15 pt-1 align-items-center mg-b-20">
              <div className="col-md-1">
                <label className="form-label">From</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="from"
                  id=""
                  name="from"
                  type="text"
                  value={userEmail}
                  onChange={handleUserEmail}
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-10  align-items-left mg-b-20">
              <div className="col-md-1">
                <label className="form-label">Send To</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="Send To"
                  id=""
                  name="to"
                  type="text"
                  value={custEmail}
                  onChange={handleCustomerEmail}
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-15 align-items-center mg-b-20">
              <div className="col-md-1">
                <label className="form-label">CC</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="CC"
                  id=""
                  name=""
                  type="text"
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-15  align-items-center">
              <div className="col-md-1">
                <label className="form-label">Subject</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="Subject"
                  id=""
                  value={subject}
                  name="subject"
                  type="text"
                  onChange={handleSubjectChange}
                />
              </div>
            </div>
            <br />
            <div className="radio col-md-12 row mg-t-15 align-items-center">
              <div className="col-md-1 pl-0"></div>
              <div className="col-md-10 pl-0">
                <div className="body">
                  <TinyMceEditor
                    onChange={setEditorContent}
                    initialContent={initialContent}
                  />

                  <div className="mx-10 mt-4">
                    <button
                      className="btn-1 btn mt-0"
                      onClick={handleCloseMailClick}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn-3 btn btn-primary"
                      onClick={handleMailSubmit}
                    >
                      {isLoading3 ? "Sending..." : "Send"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default InvoiceDetails;
